import * as React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Icon,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react"
import { AiOutlineMail, AiOutlineInstagram, AiOutlinePhone, AiOutlineGlobal } from "react-icons/ai"

export default function AssistantModal(props) {
  const { place_of_origin, instagram, website, phone, email, description, name } = props.data

  return (
    <Modal isOpen={props.isOpen} onClose={props.onToggle} size="xl">
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            <Heading as="h2" fontSize="2rem">
              {name}
            </Heading>
            <Text fontStyle="italic" fontWeight={400}>
              {place_of_origin}
            </Text>
          </ModalHeader>
          <ModalBody>
            <VStack mb={4} spacing={2} alignItems="flex-start">
              {description && <div dangerouslySetInnerHTML={{ __html: description.html }} />}
              {website && (
                <Flex alignItems="center">
                  <Icon as={AiOutlineGlobal} boxSize="2rem" mr="1rem" />
                  <Text>{website}</Text>
                </Flex>
              )}
              {email && (
                <Flex alignItems="center">
                  <Icon as={AiOutlineMail} boxSize="2rem" mr="1rem" />
                  <Text>{email}</Text>
                </Flex>
              )}
              {phone && (
                <Flex alignItems="center">
                  <Icon as={AiOutlinePhone} boxSize="2rem" mr="1rem" />
                  <Text>{phone}</Text>
                </Flex>
              )}
              {instagram && (
                <Flex alignItems="center">
                  <Icon as={AiOutlineInstagram} boxSize="2rem" mr="1rem" />
                  <Text>{instagram}</Text>
                </Flex>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
