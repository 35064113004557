const months = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
]

export function monthYear(dateString) {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const capitalizedMonth = month[0].toUpperCase() + month.substring(1)
  return `${capitalizedMonth} de ${year}`
}
