import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Heading,
  Text,
  Flex,
  Image,
  VStack,
  useMediaQuery,
  Link,
  useDisclosure,
} from "@chakra-ui/react"
import { monthYear } from "../../utils/format"
import PageLayout from "../../components/PageLayout/PageLayout"
import Logo from "../../assets/logo.png"
import Carousel from "../../components/Carousel/Carousel"
import { BREAKPOINTS } from "../../utils/constants"
import AssistantModal from "../../components/AssistantModal/AssistantModal"
import SEO from "../../components/seo/Seo"

export default function ProjectPost({ data }) {
  const [isLargerThan768] = useMediaQuery(`(min-width: ${BREAKPOINTS.md})`)
  const { isOpen, onToggle } = useDisclosure()
  const [assistantData, setAssistantData] = React.useState({})

  const {
    title,
    date,
    body,
    place,
    constructed_area,
    images,
    leaders,
    assistants,
  } = data.prismicProject.data

  const basicImage = images[0].image.url

  function handleClickAssistant(data) {
    onToggle()
    setAssistantData(data)
  }

  return (
    <PageLayout>
      <SEO title={title} description={body.text} image={basicImage} />
      <AssistantModal isOpen={isOpen} onToggle={onToggle} data={assistantData} />
      <Heading>
        <Text fontSize={("1.6rem", "1.6rem", "2rem")}>{title}</Text>
        <Text fontSize={("1.4rem", "1.4rem", "1.6rem")} fontWeight={400} fontStyle="italic">
          {place}
        </Text>
      </Heading>
      <VStack mt={["1.2rem", "1.6rem", "2.4rem"]} spacing="1.6rem">
        {isLargerThan768 ? <Carousel images={images.map(img => img.image)} /> : null}
      </VStack>
      <Flex
        mt={["1.2rem", "1.6rem", "2.4rem"]}
        justifyContent="space-between"
        flexDirection={["column", "column", "column", "row"]}
      >
        <Box maxWidth={["auto", "auto", "auto", "900px"]}>
          <div dangerouslySetInnerHTML={{ __html: body.html }} />
        </Box>
        {!isLargerThan768
          ? images.map((img, index) =>
              img.image.url ? (
                <Image
                  key={`image-${index}`}
                  src={img.image.url}
                  alt={img.image.alt}
                  w="100%"
                  my={4}
                />
              ) : null
            )
          : null}
        <Box
          ml={[0, 0, 0, "6.4rem"]}
          mt={["0.8rem", "0.8rem", "0.8rem", 0]}
          pt={["1.6rem", "1.6rem", "1.6rem", 0]}
          borderLeft={[0, 0, 0, "1px solid"]}
          borderTop={["1px solid", "1px solid", "1px solid", 0]}
          borderColor="gray.700"
          position="relative"
        >
          <Image
            src={Logo}
            alt="Urutau small logo"
            h="auto"
            w="4rem"
            position="absolute"
            bottom={0}
          />
          <Box fontWeight={300} textAlign="right" ml="6.4rem" minW="180px" whiteSpace="nowrap">
            {date || place ? (
              <Box mb="1.6rem">
                <Text fontSize="1.2rem">{monthYear(date)}</Text>
                <Text fontSize="1.2rem">{place}</Text>
              </Box>
            ) : null}
            {constructed_area ? (
              <Box mb="1.6rem">
                <Text fontSize="1.2rem">Área construída</Text>
                <Text fontSize="1.2rem">{constructed_area}m²</Text>
              </Box>
            ) : null}
            <Text fontSize="1.2rem" fontWeight={600}>
              EQUIPE
            </Text>
            {leaders.map((leader, index) =>
              leader.leader.document ? (
                <Box key={`${leader.leader.document.name}-${index}`}>
                  <Text fontSize="1.2rem" fontWeight={600}>
                    {leader.leader.document.data.name}
                  </Text>
                  <Text fontSize="1.2rem">{leader.job}</Text>
                </Box>
              ) : null
            )}
            {assistants.map((assistant, index) =>
              assistant.assistant.document ? (
                <Box key={`${assistant.assistant.document.data.name}-${index}`}>
                  <Link
                    variant="text"
                    fontSize="1.2rem"
                    fontWeight={600}
                    onClick={() => handleClickAssistant(assistant.assistant.document.data)}
                  >
                    {assistant.assistant.document.data.name}
                  </Link>
                  <Text fontSize="1.2rem">{assistant.job}</Text>
                </Box>
              ) : null
            )}
          </Box>
        </Box>
      </Flex>
    </PageLayout>
  )
}

export const query = graphql`
  query($prismicId: ID!) {
    prismicProject(prismicId: { eq: $prismicId }) {
      data {
        title
        date
        body {
          html
          text
        }
        place
        constructed_area
        images {
          image {
            url
            alt
          }
          description {
            html
          }
        }
        leaders {
          leader {
            document {
              ... on PrismicMember {
                data {
                  name
                }
              }
            }
          }
          job
        }
        assistants {
          assistant {
            document {
              ... on PrismicMember {
                data {
                  name
                  description {
                    html
                  }
                  birth
                  place_of_origin
                  instagram
                  linkedin
                  website
                  phone
                  email
                }
              }
            }
          }
          job
        }
      }
    }
  }
`
