import * as React from "react"
import {
  Flex,
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Image,
  IconButton,
} from "@chakra-ui/react"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

export default function Carousel(props) {
  const [tabsIndex, setTabsIndex] = React.useState(0)
  const { images } = props

  function handleTabsChange(index) {
    setTabsIndex(index)
  }

  function handlePreviousImage() {
    if (tabsIndex === 0) {
      setTabsIndex(images.length - 1)
    } else {
      setTabsIndex(prev => prev - 1)
    }
  }

  function handleNextImage() {
    if (tabsIndex === images.length - 1) {
      setTabsIndex(0)
    } else {
      setTabsIndex(prev => prev + 1)
    }
  }

  return (
    <Box w="100%">
      <Tabs
        isFitted={images.length > 4}
        variant="unstyled"
        onChange={handleTabsChange}
        index={tabsIndex}
      >
        <Flex>
          <Flex alignItems="center">
            <IconButton
              as={FiChevronLeft}
              boxSize="7.5rem"
              borderRadius="50%"
              cursor="pointer"
              onClick={handlePreviousImage}
            />
          </Flex>
          <TabPanels>
            {images.map(img => (
              <TabPanel key={img.url}>
                <Image margin="auto" src={img.url} maxH="500px" />
              </TabPanel>
            ))}
          </TabPanels>
          <Flex alignItems="center">
            <IconButton
              as={FiChevronRight}
              boxSize="7.5rem"
              borderRadius="50%"
              cursor="pointer"
              onClick={handleNextImage}
            />
          </Flex>
        </Flex>
        <TabList>
          {images.map(img => (
            <Tab
              borderBottom="1px solid"
              borderColor="transparent"
              transition="0.3s"
              _hover={{ boxShadow: "outline" }}
              _selected={{ boxShadow: "outline" }}
              p={0}
              mx={4}
            >
              <Image key={img.url} src={img.url} maxH="100px" />
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Box>
  )
}
